import { CorvidSDKPropsFactory } from '@wix/editor-elements-types';
import {
  SearchSuggestionGroup,
  SearchSuggestions,
  SearchSuggestionItem,
} from '@wix/thunderbolt-components';
import { withValidation } from '@wix/editor-elements-corvid-utils';

type SubmitHandler = (e: React.FormEvent) => void;
type OpenSuggestionsHandler = () => void;
type CloseSuggestionsHandler = () => void;
type ClearHandler = (e: React.MouseEvent) => void;
type ChangeHandler = (e: React.FormEvent) => void;
export type OnSuggestionsFooterClickHandler = () => void;
export type OnSuggestionSelectHandler = (
  data: SearchSuggestionItem['data'],
) => void;
export type OnSuggestionGroupSelectHandler = (
  data: SearchSuggestionGroup['data'],
) => void;

export interface ISearchBoxValuePropsSDKProps {
  value?: string | null;
  isSuggestionsOpen?: boolean;
  suggestions?: SearchSuggestions;
  suggestionsEnabled?: boolean;
}

export interface ISearchBoxValuePropsSDK {
  value?: string | null;
  suggestionsEnabled: boolean;
  openSuggestions: OpenSuggestionsHandler;
  closeSuggestions: CloseSuggestionsHandler;
  isSuggestionsOpen?: boolean;
  suggestions: SearchSuggestions;
  onSubmit: (handler: SubmitHandler) => void;
  onClear: (handler: React.MouseEventHandler) => void;
  onChange: (handler: ChangeHandler) => void;
  onSuggestionsFooterClick: (handler: OnSuggestionsFooterClickHandler) => void;
  onSuggestionSelect: (handler: OnSuggestionSelectHandler) => void;
  onSuggestionGroupSelect: (handler: OnSuggestionGroupSelectHandler) => void;
}

export interface ISearchBoxValuePropsSDKActions {
  onSubmit: (event: React.FormEvent) => void;
  onClear: (event: React.MouseEvent) => void;
  onChange: (event: React.FormEvent) => void;
  onSuggestionsFooterClick: () => void;
  onSuggestionSelect: (consumerData: any) => void;
  onSuggestionGroupSelect: () => void;
  closeSuggestions: () => void;
}

export const emptySearchSuggestions = null;

const _searchBoxValuePropsSDKFactory: CorvidSDKPropsFactory<
  ISearchBoxValuePropsSDKProps,
  ISearchBoxValuePropsSDK
> = ({ registerEvent, setProps, props }) => ({
  get value() {
    return props.value || '';
  },
  set value(_value) {
    const value = _value || '';
    setProps({ value });
  },
  get suggestions() {
    return props.suggestions || emptySearchSuggestions;
  },
  set suggestions(suggestions: SearchSuggestions | null) {
    setProps({
      isSuggestionsOpen: Boolean(suggestions?.items.length),
      suggestions,
    });
  },
  get suggestionsEnabled() {
    return props.suggestionsEnabled || false;
  },
  set suggestionsEnabled(suggestionsEnabled) {
    setProps({ suggestionsEnabled });
  },
  openSuggestions: () => {
    setProps({ isSuggestionsOpen: true });
  },
  closeSuggestions: () => {
    setProps({ isSuggestionsOpen: false });
  },
  onSubmit(handler: SubmitHandler) {
    registerEvent('onSubmit', handler);
  },
  onClear(handler: ClearHandler) {
    registerEvent('onClear', handler);
  },
  onChange(handler: ChangeHandler) {
    registerEvent('onChange', handler);
  },
  onSuggestionsFooterClick: (handler: OnSuggestionsFooterClickHandler) => {
    registerEvent('onSuggestionsFooterClick', handler);
  },
  onSuggestionSelect: (handler: OnSuggestionSelectHandler) => {
    registerEvent('onSuggestionSelect', handler);
  },
  onSuggestionGroupSelect: (handler: OnSuggestionGroupSelectHandler) => {
    registerEvent('onSuggestionGroupSelect', handler);
  },
});

export const searchBoxValuePropsSDKFactory = withValidation(
  _searchBoxValuePropsSDKFactory,
  {
    type: ['object'],
    properties: {
      label: {
        type: ['string', 'nil'],
        warnIfNil: true,
      },
      suggestionsEnabled: { type: ['boolean'] },
      suggestions: { type: ['object', 'nil'] },
      onSubmit: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onClear: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onChange: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onSuggestionsFooterClick: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onSuggestionSelect: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
      onSuggestionGroupSelect: {
        type: ['function'],
        args: [
          {
            type: ['function'],
          },
        ],
      },
    },
  },
);
