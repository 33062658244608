import {
  assert,
  withValidation,
  reportWarning,
  reportError,
  messages,
} from '@wix/editor-elements-corvid-utils';
import { TpaGallerySDKFactory } from '../TPAGallery.types';
import { parseMediaSrc } from '../../../core/corvid/media/mediaSrcHandler';

enum GALLERY_CLICK_ACTIONS {
  none = 'disabled',
  expand = 'zoomMode',
  link = 'goToLink',
}

type LINK_TYPE = 'WEBSITE' | 'DYNAMIC_PAGE_LINK';

const factory: TpaGallerySDKFactory = ({
  setProps,
  props,
  linkUtils,
  metaData: {
    compId,
    connection: { role },
  },
}) => {
  function getOrGenerateId(index: number) {
    const { images } = props;
    if (images[index]) {
      return images[index].id;
    } else {
      return `${compId}_runtime_${images[0].id}items${index}`;
    }
  }
  function convertImageOrReportError(
    src: string,
    index: number,
    title: string | undefined,
    description: string | undefined,
    resolvedLink: any,
    linkType: LINK_TYPE,
  ) {
    const {
      height,
      width,
      title: name,
      error,
      type,
      mediaId: uri,
    } = parseMediaSrc(src, 'image');
    if (error) {
      reportError(
        messages.invalidImageInGalleryWithIndex({
          wrongValue: src,
          index,
          propertyName: 'src',
        }),
      );
      return null;
    }
    const img = {
      id: getOrGenerateId(index),
      type,
      height,
      width,
      title: name,
      uri,
      ...(title && { title }),
      ...(description && { description }),
      ...(resolvedLink && {
        linkType,
        link: resolvedLink,
        href: resolvedLink.href,
      }),
    };
    return img;
  }
  return {
    get items() {
      return props.images;
    },
    set items(items) {
      const images = items.filter(
        (image: any) => !image.type || image.type.toLowerCase() === 'image',
      );
      if (images.length !== items.length) {
        reportWarning(messages.noneImageInGallery(role));
      }
      const imagesModel = images
        .map(
          (
            image: {
              src: string;
              title?: string;
              description?: string;
              link?: string;
            },
            index: number,
          ) => {
            const { title, description, link, src } = image;
            let linkType: LINK_TYPE = 'WEBSITE';
            let resolvedLink;
            if (link) {
              try {
                resolvedLink = linkUtils.getLinkProps(link, '_self');
                if (linkUtils.isDynamicPage(link)) {
                  linkType = 'DYNAMIC_PAGE_LINK';
                }
              } catch (e) {
                resolvedLink = {
                  href: link,
                };
              }
            }
            return convertImageOrReportError(
              src,
              index,
              title,
              description,
              resolvedLink,
              linkType,
            );
          },
        )
        .filter((x: any) => x !== null);

      if (images.length !== imagesModel.length) {
        return;
      }

      setProps({ images: imagesModel });
    },
    get clickAction() {
      const { compProps } = props;

      const action: 'disabled' | 'zoomMode' | 'goToLink' =
        compProps.galleryImageOnClickAction;

      switch (action) {
        case 'disabled':
          return 'none';
        case 'goToLink':
          return 'link';
        default:
        case 'zoomMode':
          return 'expand';
      }
    },
    set clickAction(action) {
      const galleryImageClickAction = assert.isNil(action)
        ? GALLERY_CLICK_ACTIONS.none
        : GALLERY_CLICK_ACTIONS[action];
      setProps({
        compProps: {
          ...props.compProps,
          galleryImageOnClickAction: galleryImageClickAction,
        },
      });
    },
  };
};

export const tpaGalleryPropsSDKFactory = withValidation(factory, {
  type: ['object'],
  properties: {
    clickAction: {
      warnIfNil: true,
      type: ['string'],
      enum: Object.keys(GALLERY_CLICK_ACTIONS),
    },
    items: {
      type: ['array'],
      items: {
        type: ['object'],
        properties: {
          src: { type: ['string', 'nil'], warnIfNil: true },
          alt: { type: ['string', 'nil'], warnIfNil: true },
          name: { type: ['string', 'nil'], warnIfNil: true },
        },
      },
      warnIfNil: true,
    },
  },
});
