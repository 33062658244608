import {
  reportError,
  // composeSDKFactories,
} from '@wix/editor-elements-corvid-utils';
import { MouseEventHandler } from 'react';
import { LinkTarget } from '@wix/editor-elements-types';
import {
  ISiteButtonOwnSdk,
  // ISiteButtonProps,
  // ISiteButtonSDK,
} from '../SiteButton.types';
// import {
//   disablePropsSDKFactory,
//   hiddenPropsSDKFactory,
//   labelPropsSDKFactory,
//   linkPropsSDKFactory,
//   elementPropsSDKFactory,
//   clickPropsSDKFactory,
// } from '../../../core/corvid/props-factories';
//
const SiteButtonSDKFactory: ISiteButtonOwnSdk = api => {
  const {
    setProps,
    props,
    registerEvent,
    linkUtils,
    setStyles,
    metaData,
  } = api;
  const _state = {
    hidden: metaData.hiddenOnLoad,
  };

  return {
    onClick: (handler: MouseEventHandler) => {
      registerEvent('onClick', handler);
      if (!props.hasPlatformClickHandler) {
        setProps({
          hasPlatformClickHandler: true,
        });
      }
    },
    onDblClick(handler: MouseEventHandler) {
      registerEvent('onDblClick', handler);
    },
    get enabled() {
      return typeof props.isDisabled !== 'undefined' ? !props.isDisabled : true;
    },
    disable: () => {
      setProps({ isDisabled: true });
      return Promise.resolve();
    },
    enable: () => {
      setProps({ isDisabled: false });
      return Promise.resolve();
    },
    get label() {
      return props.label || '';
    },
    set label(value) {
      const label = value || '';
      setProps({ label });
    },
    set link(url: string) {
      try {
        setProps({
          link: linkUtils.getLinkProps(url, props.link?.target ?? '_self'),
        });
      } catch (e) {
        reportError(
          `The link property that is passed to the link method cannot be set to the value "${url} as this is not a supported link type.`,
        );
      }
    },
    get link() {
      return props.link ? linkUtils.getLink(props.link) : '';
    },
    set target(target: LinkTarget) {
      setProps({
        link: { ...props.link, target },
      });
    },
    get target() {
      return props.link?.target ?? '_self';
    },
    hide: () => {
      setStyles({
        visibility: 'hidden',
      });
      _state.hidden = true;
      return Promise.resolve();
    },
    show: () => {
      setStyles({
        visibility: 'visible',
      });
      _state.hidden = false;
      return Promise.resolve();
    },
    get hidden() {
      return Boolean(_state.hidden);
    },
  };
};

// export const sdk = composeSDKFactories<ISiteButtonProps, ISiteButtonSDK>(
//   clickPropsSDKFactory,
//   labelPropsSDKFactory,
//   disablePropsSDKFactory,
//   hiddenPropsSDKFactory,
//   linkPropsSDKFactory,
//   elementPropsSDKFactory,
//   SiteButtonSDKFactory,
// );

export const sdk = SiteButtonSDKFactory as any;
