import {
  withValidation,
  composeSDKFactories,
  createCompSchemaValidator,
  reportWarning,
} from '@wix/editor-elements-corvid-utils';
import * as messages from '@wix/editor-elements-corvid-utils/dist/messages/messages';
import { ComboBoxInputOption } from '@wix/thunderbolt-components/src/components/ComboBoxInput/ComboBoxInput';
import { isNil } from '@wix/editor-elements-corvid-utils/dist/assert/assert';
import {
  IComboBoxInputImperativeActions,
  IComboBoxInputOwnSDKFactory,
  IComboBoxInputProps,
  IComboBoxInputSDK,
} from '../ComboBoxInput.types';
import {
  composeSanitizers,
  numberToString,
  emptyStringIfNotString,
  createInputValidator,
  validateRequired,
  composeValidators,
  InputValidator,
} from '../../../core/corvid/inputUtils';
import {
  createValuePropsSdkFactory,
  createRequiredPropsSDKFactory,
  focusPropsSDKFactory,
  createValidationPropsSDKFactory,
  disablePropsSDKFactory,
  clickPropsSDKFactory,
  hiddenPropsSDKFactory,
  collapsedPropsSDKFactory,
  createStylePropsSDKFactory,
  elementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';

const comboBoxInputValidator: InputValidator<
  IComboBoxInputProps,
  IComboBoxInputImperativeActions
> = createInputValidator(
  composeValidators<IComboBoxInputProps>([validateRequired]),
);

const requiredPropsSDKFactory = createRequiredPropsSDKFactory(
  comboBoxInputValidator,
);

const validationPropsSDKFactory = createValidationPropsSDKFactory(
  comboBoxInputValidator,
);

const valueSanitizer = composeSanitizers([
  numberToString,
  emptyStringIfNotString,
]);

const valuePropsSDKFactory = createValuePropsSdkFactory(
  value => valueSanitizer(value),
  { type: ['string'] },
  comboBoxInputValidator,
);

const sdkOptionPropertyName = 'selectOption';

export type SdkComboBoxOption = {
  value?: string | null;
  label?: string | null;
};

const isValidOption = (option: SdkComboBoxOption): boolean => {
  const hasSomeValue = !!option.value || !!option.label;
  const isValidEmptyOption = option.value === '' && option.label === '';
  return hasSomeValue || isValidEmptyOption;
};

const throwInvalidOptionsWarnings = (options: Array<SdkComboBoxOption>): void =>
  options.forEach((option, index) => {
    if (!isValidOption(option)) {
      reportWarning(
        messages.invalidOption({
          propertyName: sdkOptionPropertyName,
          wrongValue: option,
          index,
        }),
      );
    }
  });

const isValueInOptions = (
  options: Array<ComboBoxInputOption>,
  value: string,
): boolean => options.some(option => option.value === value);

const emptyStringIfNotAnOption = (
  options: Array<ComboBoxInputOption>,
  value: string,
): string => (isValueInOptions(options, value) ? value : '');

const comboBoxInputCorvidType = 'Dropdown';

const _ownSDKFactory: IComboBoxInputOwnSDKFactory = api => {
  const { props, setProps, metaData } = api;
  const schemaValidator = createCompSchemaValidator(metaData.role);

  // Hold an intermediate state for cases when a new (valid) value is set before options are changed.
  const _state: { value?: string } = {};

  const sdkProps = {
    get options() {
      const { options } = props;
      return options
        ? options.map((option: ComboBoxInputOption) => ({
            label: option.text,
            value: option.value,
          }))
        : [];
    },
    set options(_options) {
      const options = _options || [];
      throwInvalidOptionsWarnings(options);

      const newOptions = options
        .filter((option: SdkComboBoxOption) => isValidOption(option))
        .map((option: SdkComboBoxOption, index: number) => ({
          key: `${index}`,
          value: option.value || '',
          text: option.label || '',
        }));

      const commonProps = { options: newOptions };
      const supportSettingValueBeforeOptionsFlow =
        _state.value && isValueInOptions(newOptions, _state.value);

      if (supportSettingValueBeforeOptionsFlow) {
        setProps({
          ...commonProps,
          value: _state.value,
        });

        _state.value = undefined;
      } else {
        setProps(commonProps);
      }
    },
    get placeholder() {
      return props.placeholder.value || '';
    },
    set placeholder(_placeholder) {
      const placeholder = _placeholder || '';
      setProps({
        placeholder: {
          value: placeholder,
          text: placeholder,
        },
      });
    },
    get selectedIndex() {
      const selectedIndex = props.options.findIndex(
        (option: ComboBoxInputOption) => option.value === props.value,
      );
      return selectedIndex !== -1 ? selectedIndex : undefined;
    },
    set selectedIndex(index) {
      const isValid = schemaValidator(
        index,
        {
          type: ['integer', 'nil'],
          minimum: 0,
          maximum: props.options.length - 1,
        },
        'value',
      );
      if (!isValid) {
        return;
      }
      const newSelectedValue = isNil(index) ? '' : props.options[index].value;
      setProps({
        value: newSelectedValue,
      });

      comboBoxInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get value() {
      const isCurrentValueInOptions = isValueInOptions(
        props.options,
        props.value,
      );
      return isCurrentValueInOptions ? props.value : '';
    },
    set value(value) {
      const sanitizedValue = valueSanitizer(value);
      const isValid = schemaValidator(
        sanitizedValue,
        { type: ['string'] },
        'value',
      );
      if (!isValid) {
        return;
      }
      _state.value = sanitizedValue;

      const newValue = emptyStringIfNotAnOption(props.options, sanitizedValue);
      setProps({ value: newValue });

      comboBoxInputValidator.validate({
        viewerSdkAPI: api,
        showValidityIndication: true,
      });
    },
    get type() {
      return `$w.${comboBoxInputCorvidType}`;
    },
    toJSON() {
      const { required } = props;
      const { value, options, placeholder, selectedIndex } = sdkProps;
      return {
        ...toJSONBase(metaData),
        required,
        value,
        options,
        placeholder,
        selectedIndex,
      };
    },
  };

  return sdkProps;
};

const ownSDKFactory = withValidation(_ownSDKFactory, {
  type: ['object'],
  properties: {
    options: {
      type: ['array', 'nil'],
      warnIfNil: true,
      items: {
        type: ['object'],
        properties: {
          value: {
            type: ['string', 'nil'],
            minLength: 0,
            maxLength: 400,
          },
          label: {
            type: ['string', 'nil'],
            minLength: 0,
            maxLength: 400,
          },
        },
      },
    },
    placeholder: {
      type: ['string', 'nil'],
      warnIfNil: true,
    },
    selectedIndex: {
      type: ['integer', 'nil'],
    },
  },
});

const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
  TextColor: true,
});

export const sdk = composeSDKFactories<
  IComboBoxInputProps,
  IComboBoxInputSDK,
  any
>(
  elementPropsSDKFactory,
  requiredPropsSDKFactory,
  validationPropsSDKFactory,
  focusPropsSDKFactory,
  valuePropsSDKFactory,
  disablePropsSDKFactory,
  hiddenPropsSDKFactory,
  clickPropsSDKFactory,
  collapsedPropsSDKFactory,
  stylePropsSDKFactory,
  ownSDKFactory,
);
